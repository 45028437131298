<template>

    <auth-content>
      <el-header :title="$t('modules.wiki.title')" slot="header">
        <div slot="actions">
          <el-header-action :disabled="loading" size="xs" icon="plus3" :label="$t('modules.wiki.content.button_text_add')" @click.native="createContent()"/>
          <el-header-action classes="btn-secondary" size="xs" icon="code" :label="$t('modules.wiki.tokens.button_text')" @click.native="$router.push({name: 'wiki.tokens', params: {world: $route.params.world} })"/>
        </div>
      </el-header>

      <el-filters :filters="filters" v-if="Object.keys(content).length > 1"/>
      <div v-if="Object.keys(content).length > 0">
        <div v-show="areFiltersClean">

          <div class="card">
            <div class="card-header">
              <el-card-title :title="$t('modules.wiki.content.last_title')"/>
            </div>
            <div class="card-body">
              <div v-for="content in lastContentFormatted" :key="content.id">
                <wiki-content-teaser
                  :content="content"
                />
              </div>
            </div>
          </div>

          <div class="row" v-if="filters[0]">
            <div class="col-md-6 col-xl-4" v-for="(category, categoryKey) in filters[0].options">
              <div class="card cursor-pointer" @click="filters[0].options[categoryKey].value = true">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-3 text-center">
                      <img class="cat-picto" v-if="category.raw_values[0]" :src="require('@/assets/images/categories/' + category.raw_values[0] + '.svg')" @error="defaultCatImage">
                      <img class="cat-picto" v-else :src="require('@/assets/images/mascot-circle.svg')">
                    </div>
                    <div class="col-9">
                      <h4 class="m-0">{{ category.title }}</h4>
                    </div>
                  </div>
                </div>

                <div class="card-footer border-0 px-2 py-1 d-sm-flex justify-content-sm-between align-items-sm-center">
                  <ul class="list-inline list-inline-dotted mb-0">
                    <li class="list-inline-item"><span class="small px-2 text-grey">{{ $tc('modules.wiki.content.card_count_content', category.count) }}</span></li>
                  </ul>

                  <ul class="list-inline list-inline-dotted mb-0 mt-1 mt-sm-0">
                    <li class="list-inline-item"><span class="btn btn-link btn-sm">{{ $tc('modules.wiki.content.card_view_content', category.count) }}<i class="icon-arrow-right8 ml-1"></i></span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div v-show="!areFiltersClean">
          <div class="card">
            <div class="card-header">
              <h4 class="text-secondary font-weight-bold m-0">{{ $t('common.your_search') }}</h4>
            </div>
            <div class="card-body">
              <div v-for="content in contentFiltered" class="" :key="content.id">
                <wiki-content-teaser
                  :content="content"
                />
              </div>
            </div>
          </div>

        </div>


      </div>
      <el-empty v-else :title="$t('modules.wiki.content.empty_title')" :text="isCurrentWorldOwner ? $t('modules.wiki.content.empty_text_owner') : $t('modules.wiki.content.empty_text_other')"></el-empty>

    </auth-content>

</template>

<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '@/store'
import WikiContentTeaser from '../components/WikiContentTeaser'

import {
  WIKI_GET_CONTENTS,
  WIKI_CREATE_CONTENT,
} from '../mutations-types'

export default {
  name: 'WikiContentIndex',
  components: {
    WikiContentTeaser,
  },
  data () {
    return {
      lastContentToShow: 5,
      contentToSend: false,
      contentToSendToUserId: false,
      loading: false,
      content: false,
      columns: {
        name: this.$t('modules.wiki.content.fields.name'),
        category_label: this.$t('modules.wiki.content.fields.category'),
      },
      filters: false
    }
  },
  mounted () {
  },
  methods: {
    defaultCatImage (event) {
      event.target.src = require('@/assets/images/mascot-circle.svg')
    },
    createContent () {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$store.dispatch('wiki/' + WIKI_CREATE_CONTENT, {
        world_id: this.$route.params.world,
        name: this.$t('modules.wiki.content.new_title'),
      }).then(content => {
        this.$router.push({name: 'wiki.content', params: { world: this.$route.params.world, content: content.id}})
      })
    },
    cleanFilters () {
      _.forEach(this.filters, filter => {
        _.forEach(filter.options, option => {
          option.value = false
        })
      })
    },
    loadFilters () {
      let content = {...this.formatContent()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.wiki.content.fields.category'),
          key: 'category_id',
          options: this.getFilterOptions(content, 'category_id', this.$t('modules.quest.categories'), this.$t('common.to_classify')),
        },
        {
          title: this.$t('modules.wiki.content.fields.hashtags'),
          key: 'hashtags',
          options: this.getFilterOptions(content, 'hashtags'),
        },
        {
          title: this.$t('moduleentity.template.title'),
          key: 'template',
          options: this.getFilterOptions(content, 'template', {'true': this.$t('common.yes')}, this.$t('common.no')),
        },
      ])
    },
    formatContent () {
      let contentArray = []
      if (this.content) {
        // Get content
        _.map(this.content, (elem) => {
          let content = {
            id: elem.id,
            link: this.$router.resolve({name: 'wiki.content', params: { world: this.$route.params.world, content: elem.id } }).href,
            name: elem.name,
            user: elem.user,
            updated_at: elem.updated_at,
            has_access: elem.has_access,
            category_id: elem.category ? elem.category.name : '',
            category_label: elem.category ? this.$t('modules.quest.categories.' + elem.category.name) : '',
            hashtags: elem.mentions.hashtags,
            template: elem.template || null,
          }
          contentArray.push(content);
        })
      }
      return contentArray
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('wiki/' + WIKI_GET_CONTENTS, to.params.world).then(content => {
      next(vm => {
        vm.content = {...content}
        vm.loadFilters()
      })
    })
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    areFiltersClean () {
      return this.$store.getters['global/areFiltersClean']
    },
    isSidebarRightOpened () {
      return this.$store.getters['global/isSidebarRightOpened']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    lastContentFormatted () {
      return _.cloneDeep(this.contentFormatted).splice(0, this.lastContentToShow)
    },
    contentFormatted () {
      return _.orderBy(this.formatContent(), ['updated_at', 'name'], ['desc', 'asc'])
    },
    contentFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.contentFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.contentFormatted, this.filters), {
        shouldSort: true,
        ignoreLocation: true,
        threshold: 0.3,
        // distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
          "category_label"
        ]
      }).search(searchValue)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}
.cat-picto {
  height: 60px;
  object-fit:contain;
}
</style>
